
.details_header {
    color: var(--special_color_dark);
}

.details_header_secondary {
    color: darkgrey;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.modal-header .focused {
    border-bottom: 3px solid #ededed;
}

.modal-header a {
    color: var(--special_color_dark) !important;
}

.modal-header .focused a {
    color: #ededed !important;
}

.accordion_header {
    cursor: pointer;
}

.modal-pre-text {
    white-space: break-spaces;
    padding: 0 20px;
}