
.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error_message {
  color:darkred;
}