body {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
}

.container {
  max-width: 1280px !important;
} 

.special_color_light {
  background-color: var(--special_color_light) !important;
}

.special_color {
  background-color: var(--special_color) !important;
}

.special_color_dark {
  background-color: var(--special_color_dark) !important;
}

nav .nav-link:hover {
  border-bottom: 2px solid var(--special_color) !important;
  padding-bottom: 6px;
}

.navbar {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-bottom: 2px solid var(--special_color) !important;
}

.md-form label {
  color: #495057 !important;
}

.logotype_navbar {
  background-color: var(--special_color_light);
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.logotype-divider {
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
  padding: 0 20px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--special_color_dark);
}

.affiliations_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.affiliations_form input {
  min-width: 300px;
}

.affiliations_form .md-form {
  margin: 8px 0;
}

.no-wrap {
  white-space: nowrap;
}

.async_field {
  min-width: 300px;
}

.filter_select {
  max-width: 300px;
}
