/* Here we can change navbar/footer images and logotype title parameters as well as set main site colors */

/* Main site colors settings*/
:root {
  --special_color_light: #ffffff;
  --special_color: #6ba439;
  --special_color_dark: #0f2834;
}

/* Navbar logotype image settings*/
.logotype-image {
  height: 40px;
}

/* Navbar logotype title settings*/
.logotype-title {
  font-weight: bold;
  font-size: 1.4rem;
  color: #fff;
}

/* Footer logotype image settings*/
.logotype-footer-image {
  height: 18px;
}

/* Footer logotype title settings*/
.logotype-footer-title {
  color: #fff;
  line-height: initial;
}

